import React from "react"
import Img from "gatsby-image"
import avatarPlaceholder from "../images/frog-avatar.png"

const review = props => {
  console.log(props)
  return (
    <>
      <div className={props.imageStyle}>
        {props.image ? (
          <Img fixed={props.image.asset.fixed} />
        ) : (
          <img src={avatarPlaceholder} alt="meerkat avatar" />
        )}
      </div>
      <h4>{props.reviewer}</h4>
      <p>{props.review}</p>
    </>
  )
}

export default review
