import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Review from "../components/review-list-item"

import reviewStyles from "../styles/reviews.module.scss"

export const query = graphql`
  query ReviewQuery {
    reviews: allSanityReview {
      edges {
        node {
          id
          image {
            asset {
              fixed(height: 120, width: 120) {
                ...GatsbySanityImageFixed
              }
            }
          }
          review
          reviewer
        }
      }
    }
  }
`

const ReviewList = props => {
  console.log(props)
  return (
    <section className={reviewStyles.allReviewsContainer}>
      {props.nodes &&
        props.nodes.map(node => (
          <article className={reviewStyles.reviewContainer} key={node.id}>
            <Review imageStyle={reviewStyles.reviewImage} {...node} />
          </article>
        ))}
    </section>
  )
}

const Reviews = ({ data }) => {
  const reviewNodes = data && data.reviews.edges.map(edge => edge.node)
  return (
    <Layout>
      <h1>Reviews</h1>
      <ReviewList nodes={reviewNodes} />
    </Layout>
  )
}

export default Reviews
